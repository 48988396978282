import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import range from 'lodash/range'

import IntervalTree from 'node-interval-tree'
import { buildKeywordsRegExp, pairs } from 'shared/lib/util.ts'
import { captureException } from '@sentry/browser'

import {
  TranscriptConversation,
  TranscriptConversationUtterance,
  User,
  UtteranceAudio,
  UtteranceAudioSentence,
  QuestionConnection,
  Transcript,
  TranscriptAnnotation,
  Maybe,
  InterviewConversation,
  InterviewUtterance
} from 'shared/graphql_types.ts'

// eslint-disable-next-line rulesdir/illegal-cross-package-imports
import {
  EventTranscriptSection,
  ExpertInterviewConversation,
  ConversationUtterance,
  ConversationUtteranceAudio,
  ConversationDocumentSnippet,
  QuestionConnection as QuestionConnectionClient,
  Transcript as TranscriptClient,
  TranscriptCompanyMentionsSnippet,
  User as UserClient
} from 'client/graphql_types.ts'
// eslint-disable-next-line rulesdir/illegal-cross-package-imports
import { ConversationSentenceAudioWithUtterance } from 'client/composables/document_viewer/types.ts'

export interface Company {
  id: string
  name: string
}

export interface HighlightRegion {
  utteranceFrom: number
  paragraphFrom: number
  textFrom: number
  utteranceTo: number
  paragraphTo: number
  textTo: number
}

export interface Annotation {
  __typename?: string
  createdAt: Date
  highlightedFromCursor: number
  /** The highlighted text, rendered to HTML. */
  highlightedTextHtml: string
  highlightedToCursor: number
  id: string
  paragraphFrom: number
  paragraphTo: number
  readonly: boolean
  text?: Maybe<string>
  updatedAt: Date
  // TO DO: create generic version of user
  user: User | UserClient
  utteranceFrom: number
  utteranceTo: number
  type: string
  documentId?: string
  documentType?: string
  // TO DO: create generic version of questions
  questions?: QuestionConnection | QuestionConnectionClient
  // TO DO: create generic version of transcript
  transcript?: Transcript | TranscriptClient
}

export interface HighlightSpan<T> {
  object: T
  from: number
  to: number
  classes: string[]
  ids: string[]
}

export interface DocumentEntity {
  company?: Maybe<Company>
  from: number
  paragraph: number
  to: number
  utterance: number
}

// A text fragment represents a piece of the original conversation text, with
// some data indicating if and how it should be rendered as a highlight.
export interface TextFragment<T> {
  span: HighlightSpan<T> | null
  text: string
  entity?: DocumentEntity
}

// Transcript annotations and literal text (e.g., search terms) are
// highlightable.
export type TranscriptHighlightable =
  Annotation
  | ConversationDocumentSnippet
  | TranscriptCompanyMentionsSnippet
  | UtteranceAudioSentence | ConversationSentenceAudioWithUtterance
  | DocumentEntity
  | HighlightRegion
  | string

export interface Utterance {
  speaker: TextFragment<TranscriptHighlightable>[]
  speakerDetails?: TextFragment<TranscriptHighlightable>[]
  paragraphs: TextFragment<TranscriptHighlightable>[][]
  audio: UtteranceAudio | ConversationUtteranceAudio | null | undefined
  isClient: boolean | undefined
}

export interface Conversation {
  utterances: Utterance[]
  type?: string
}

// A span represents the parts of a highlightable that appear in a particular
// utterance and paragraph of the conversation text. Highlightables may
// overlap, in which case `objects` contains all highlightables that overlap in
// that particular span of text.
export interface Span<T> {
  objects: T[]
  utterance: number
  paragraph: number
  highlightedFromCursor: number
  highlightedToCursor: number
}

export type CommonConversation = TranscriptConversation | EventTranscriptSection | ExpertInterviewConversation | InterviewConversation
export type CommonUtterance = TranscriptConversationUtterance | ConversationUtterance | InterviewUtterance

export const speakerParagraphIndex = -1

function speakerLength (utterance: CommonUtterance): number {
  const speakerDetailsLength = 'speakerDetails' in utterance ? (utterance.speakerDetails?.length || 0) : 0

  return utterance.speaker.length + speakerDetailsLength
}

export function isTranscriptAnnotation (annotation: Annotation): annotation is TranscriptAnnotation {
  return Object.prototype.hasOwnProperty.call(annotation, 'transcript')
}

function isSearchTermHighlight (x: TranscriptHighlightable): x is string {
  return typeof x === 'string'
}

function isTranscriptKeywordSnippetHighlight (
  x: TranscriptHighlightable
): x is ConversationDocumentSnippet {
  return (x as ConversationDocumentSnippet).terms?.length != null
}

function isSelectedTranscriptKeywordSnippetHighlight (
  x: TranscriptHighlightable,
  selectedKeywordSnippet: ConversationDocumentSnippet
): x is ConversationDocumentSnippet {
  const possibleSnippet = x as ConversationDocumentSnippet
  return (
    possibleSnippet.terms?.length != null && possibleSnippet.id === selectedKeywordSnippet.id
  )
}

function isCompanyMentionsSnippetHighlight (
  x: TranscriptHighlightable
): x is TranscriptCompanyMentionsSnippet {
  const possibleSnippet = x as TranscriptCompanyMentionsSnippet
  return (
    !!possibleSnippet.company &&
    (!!possibleSnippet.surroundingSentenceStartIndex || !!possibleSnippet.surroundingSentenceEndIndex)
  )
}

function isHighlightRegion (x: TranscriptHighlightable): boolean {
  if (typeof x !== 'object') {
    return false
  }
  return ['utteranceFrom', 'paragraphFrom', 'textFrom', 'utteranceTo', 'paragraphTo', 'textTo'].every(k => {
    return Object.prototype.hasOwnProperty.call(x, k)
  })
}

function isSelectedCompanyMentionsSnippetHighlight (
  x: TranscriptHighlightable,
  selectedCompanyMentionsSnippet: TranscriptCompanyMentionsSnippet
): x is TranscriptCompanyMentionsSnippet {
  const possibleSnippet = x as TranscriptCompanyMentionsSnippet
  return (
    isCompanyMentionsSnippetHighlight(possibleSnippet) &&
    possibleSnippet.id === selectedCompanyMentionsSnippet.id
  )
}

function isTranscriptAudioSentence (x: TranscriptHighlightable): x is UtteranceAudioSentence {
  return (x as UtteranceAudioSentence).time != null
}

function isTranscriptEntity (x: TranscriptHighlightable) {
  return (Object.prototype.hasOwnProperty.call(x, 'company') && !(isCompanyMentionsSnippetHighlight(x)))
}

function isActiveCompanyMentionsCompany (
  x: TranscriptHighlightable,
  activeCompanyMentionsCompany: Company
) {
  return (
    isTranscriptEntity(x) &&
    (x as DocumentEntity).company?.id === activeCompanyMentionsCompany.id
  )
}

function isAnnotationHighlight (
  x: TranscriptHighlightable
): x is Annotation {
  return (
    !isSearchTermHighlight(x) &&
    !isTranscriptAudioSentence(x) &&
    !isTranscriptEntity(x) &&
    !isTranscriptKeywordSnippetHighlight(x) &&
    !isCompanyMentionsSnippetHighlight(x) &&
    !isHighlightRegion(x)
  )
}

function sortUniq (xs: number[]): number[] {
  return Array.from(new Set(xs)).sort((a, b) => Number(a) - Number(b))
}

export function splitOverlappingSpans<T> (
  utterance: number,
  paragraph: number,
  spans: Span<T>[]
): Span<T>[] {
  const intervalTree = new IntervalTree<Span<T>>()

  // Discard empty spans and insert into tree
  spans
    .filter(s => s.highlightedFromCursor < s.highlightedToCursor)
    .forEach(s => intervalTree.insert(s.highlightedFromCursor, s.highlightedToCursor - 1, s))

  const intervals = pairs(
    sortUniq(flatMap(spans, s => [s.highlightedFromCursor, s.highlightedToCursor]))
  )
  return intervals.reduce((spans: Span<T>[], [highlightedFromCursor, highlightedToCursor]) => {
    const overlaps = intervalTree.search(
      highlightedFromCursor,
      highlightedToCursor - 1
    )

    if (overlaps.length) {
      return [
        ...spans,
        {
          objects: flatMap(overlaps, s => s.objects),
          utterance,
          paragraph,
          highlightedFromCursor,
          highlightedToCursor
        }
      ]
    } else {
      return spans
    }
  }, [])
}

class InvalidSnippetError extends Error {
  readonly snippet: ConversationDocumentSnippet
  readonly conversation: CommonConversation

  constructor (snippet: ConversationDocumentSnippet, conversation: CommonConversation) {
    super('Could not create span for snippet')
    this.snippet = snippet
    this.conversation = conversation
  }
}

function validateSnippet (
  conversation: CommonConversation,
  snippet: ConversationDocumentSnippet
) {
  const startUtterance = conversation.utterances[snippet.utteranceFrom]
  const endUtterance = conversation.utterances[snippet.utteranceTo]
  const startParagraph = startUtterance?.paragraphs[snippet.paragraphFrom]
  const endParagraph = endUtterance?.paragraphs[snippet.paragraphTo]

  if (!startUtterance || !endUtterance || !startParagraph || !endParagraph) {
    throw new InvalidSnippetError(snippet, conversation)
  }
}

function getKeywordSnippetHighlightedToCursor (
  conversation: CommonConversation,
  snippet: ConversationDocumentSnippet,
  utterance: number,
  paragraph: number
): number {
  const currentUtterance = conversation.utterances[utterance]

  if (!currentUtterance || !currentUtterance.paragraphs.length) {
    // Utterance either doesn't exist or has no paragraphs
    throw new InvalidSnippetError(snippet, conversation)
  }

  if (paragraph === speakerParagraphIndex) {
    return speakerLength(currentUtterance)
  }

  if (!currentUtterance.paragraphs[paragraph]) {
    // Paragraph doesn't exist so get length of last paragraph
    return currentUtterance.paragraphs[currentUtterance.paragraphs.length - 1].length
  }

  return currentUtterance.paragraphs[paragraph].length
}

function makeKeywordSnippetSpan (
  conversation: CommonConversation,
  snippet: ConversationDocumentSnippet,
  utterance: number,
  paragraph: number,
  highlightedFromCursor: number = 0,
  highlightedToCursor?: number
): Span<ConversationDocumentSnippet> {
  if (highlightedToCursor === undefined) {
    try {
      highlightedToCursor = getKeywordSnippetHighlightedToCursor(conversation, snippet, utterance, paragraph)
    } catch (err) {
      if (err instanceof InvalidAnnotationError) {
        captureException(err, { data: { snippet: err.annotation, conversation: err.conversation } })
      }
      highlightedFromCursor = 0
      highlightedToCursor = 0
    }
  }

  return {
    objects: [snippet],
    utterance,
    paragraph,
    highlightedFromCursor,
    highlightedToCursor
  }
}

function makeKeywordSnippetSpans (
  conversation: CommonConversation,
  keywordSnippets: ConversationDocumentSnippet[]
): Span<ConversationDocumentSnippet>[] {
  return flatMap(keywordSnippets, a => {
    try {
      validateSnippet(conversation, a)
    } catch (err) {
      if (err instanceof InvalidSnippetError) {
        captureException(err, { data: { snippet: err.snippet, conversation: err.conversation } })
      }
      return []
    }

    if (a.utteranceFrom !== a.utteranceTo) {
      // Cross utterance
      return [
        makeKeywordSnippetSpan(conversation, a, a.utteranceFrom, a.paragraphFrom, a.textFrom),

        ...range(
          a.paragraphFrom + 1,
          conversation?.utterances[a.utteranceFrom].paragraphs.length
        ).map(paragraph => makeKeywordSnippetSpan(conversation, a, a.utteranceFrom, paragraph)),

        ...flatMap(range(a.utteranceFrom + 1, a.utteranceTo), utterance => {
          return range(
            speakerParagraphIndex,
            conversation?.utterances[utterance].paragraphs.length
          ).map(paragraph => makeKeywordSnippetSpan(conversation, a, utterance, paragraph))
        }),

        ...range(speakerParagraphIndex, a.paragraphTo).map(paragraph =>
          makeKeywordSnippetSpan(conversation, a, a.utteranceTo, paragraph)
        ),

        makeKeywordSnippetSpan(conversation, a, a.utteranceTo, a.paragraphTo, 0, a.textTo)
      ]
    } else if (a.paragraphFrom !== a.paragraphTo) {
      // Cross paragraph
      return [
        makeKeywordSnippetSpan(conversation, a, a.utteranceFrom, a.paragraphFrom, a.textFrom),

        ...range(a.paragraphFrom + 1, a.paragraphTo).map(paragraph =>
          makeKeywordSnippetSpan(conversation, a, a.utteranceFrom, paragraph)
        ),

        makeKeywordSnippetSpan(conversation, a, a.utteranceFrom, a.paragraphTo, 0, a.textTo)
      ]
    } else {
      // Within a single paragraph
      return [
        makeKeywordSnippetSpan(conversation, a, a.utteranceFrom, a.paragraphFrom, a.textFrom, a.textTo)
      ]
    }
  })
}

function makeCompanyMentionsSnippetSpans (
  companyMentionsSnippets: TranscriptCompanyMentionsSnippet[]
): Span<TranscriptCompanyMentionsSnippet>[] {
  return companyMentionsSnippets.map(k => ({
    objects: [k],
    utterance: k.utterance,
    paragraph: k.paragraph,
    highlightedFromCursor: k.surroundingSentenceStartIndex,
    highlightedToCursor: k.surroundingSentenceEndIndex
  }))
}

function makeUtteranceSpans (conversation: CommonConversation, utterance: number, region: HighlightRegion): Span<HighlightRegion>[] {
  const currentUtterance = conversation.utterances[utterance]
  if (!currentUtterance) {
    return []
  }

  const spans: Span<HighlightRegion>[] = []
  const isFirstUtterance = utterance === region.utteranceFrom
  const isLastUtterance = utterance === region.utteranceTo
  const startParagraph = isFirstUtterance ? region.paragraphFrom : 0
  const endParagraph = isLastUtterance ? region.paragraphTo : currentUtterance.paragraphs.length - 1

  for (let paragraph = startParagraph; paragraph <= endParagraph; paragraph++) {
    const isFirstParagraph = isFirstUtterance && startParagraph === paragraph
    const isLastParagraph = isLastUtterance && endParagraph === paragraph
    const startText = isFirstParagraph ? region.textFrom : 0
    const endText = isLastParagraph
      ? region.textTo
      : currentUtterance.paragraphs[paragraph]
        ? currentUtterance.paragraphs[paragraph].length
        : currentUtterance.paragraphs[currentUtterance.paragraphs.length - 1].length

    spans.push({
      objects: [region],
      utterance,
      paragraph,
      highlightedFromCursor: startText,
      highlightedToCursor: endText
    })
  }

  return spans
}

function makeHighlightRegions (
  conversation: CommonConversation,
  highlightRegions: HighlightRegion[]
): Span<HighlightRegion>[] {
  const spans: Span<HighlightRegion>[] = []

  for (const region of highlightRegions) {
    for (let utterance = region.utteranceFrom; utterance <= region.utteranceTo; utterance++) {
      spans.push(...makeUtteranceSpans(conversation, utterance, region))
    }
  }
  return spans
}

function makeSentenceAudioSpans (
  currentAudioSentence: UtteranceAudioSentence | ConversationSentenceAudioWithUtterance
): Span<UtteranceAudioSentence | ConversationSentenceAudioWithUtterance>[] {
  const s = currentAudioSentence

  return [
    {
      objects: [s],
      utterance: s.utterance as number,
      paragraph: s.paragraph,
      highlightedFromCursor: s.from,
      highlightedToCursor: s.to
    }
  ]
}

function makeEntitySpans (entities: DocumentEntity[]): Span<DocumentEntity>[] {
  return entities.map(entity => ({
    objects: [entity],
    utterance: entity.utterance as number,
    paragraph: entity.paragraph,
    highlightedFromCursor: entity.from,
    highlightedToCursor: entity.to
  }))
}

class InvalidAnnotationError extends Error {
  readonly annotation: Annotation
  readonly conversation: CommonConversation

  constructor (annotation: Annotation, conversation: CommonConversation) {
    super('Could not create span for annotation')
    this.annotation = annotation
    this.conversation = conversation
  }
}

function validateAnnotation (
  conversation: CommonConversation,
  annotation: Annotation
) {
  const startUtterance = conversation.utterances[annotation.utteranceFrom]
  const endUtterance = conversation.utterances[annotation.utteranceTo]

  const startParagraph = annotation.paragraphFrom === speakerParagraphIndex
    ? annotation.paragraphFrom
    : startUtterance?.paragraphs[annotation.paragraphFrom]
  const endParagraph = annotation.paragraphTo === speakerParagraphIndex
    ? annotation.paragraphTo
    : endUtterance?.paragraphs[annotation.paragraphTo]

  if (!startUtterance || !endUtterance || !startParagraph || !endParagraph) {
    throw new InvalidAnnotationError(annotation, conversation)
  }
}

function getHighlightedToCursor (
  conversation: CommonConversation,
  annotation: Annotation,
  utterance: number,
  paragraph: number
): number {
  const currentUtterance = conversation.utterances[utterance]

  if (!currentUtterance || !currentUtterance.paragraphs.length) {
    // Utterance either doesn't exist or has no paragraphs
    throw new InvalidAnnotationError(annotation, conversation)
  }

  if (paragraph === speakerParagraphIndex) {
    return speakerLength(currentUtterance)
  }

  if (!currentUtterance.paragraphs[paragraph]) {
    // Paragraph doesn't exist so get length of last paragraph
    return currentUtterance.paragraphs[currentUtterance.paragraphs.length - 1].length
  }

  return currentUtterance.paragraphs[paragraph].length
}

function makeAnnotationSpan (
  conversation: CommonConversation,
  annotation: Annotation,
  utterance: number,
  paragraph: number,
  highlightedFromCursor: number = 0,
  highlightedToCursor?: number
): Span<Annotation> {
  if (highlightedToCursor === undefined) {
    try {
      highlightedToCursor = getHighlightedToCursor(conversation, annotation, utterance, paragraph)
    } catch (err) {
      if (err instanceof InvalidAnnotationError) {
        captureException(err, { data: { snippet: err.annotation, conversation: err.conversation } })
      }
      highlightedFromCursor = 0
      highlightedToCursor = 0
    }
  }

  return {
    objects: [annotation],
    utterance,
    paragraph,
    highlightedFromCursor,
    highlightedToCursor
  }
}

function makeAnnotationSpans (
  conversation: CommonConversation,
  annotations: Annotation[]
): Span<Annotation>[] {
  return flatMap(annotations, (a: Annotation) => {
    try {
      validateAnnotation(conversation, a)
    } catch (err) {
      if (err instanceof InvalidAnnotationError) {
        captureException(err, { data: { snippet: err.annotation, conversation: err.conversation } })
      }
      return []
    }

    if (a.utteranceFrom !== a.utteranceTo) {
      // Cross utterance
      return [
        makeAnnotationSpan(conversation, a, a.utteranceFrom, a.paragraphFrom, a.highlightedFromCursor),

        ...range(
          a.paragraphFrom + 1,
          conversation.utterances[a.utteranceFrom].paragraphs.length
        ).map(paragraph => makeAnnotationSpan(conversation, a, a.utteranceFrom, paragraph)),

        ...flatMap(range(a.utteranceFrom + 1, a.utteranceTo), utterance => {
          return range(
            speakerParagraphIndex,
            conversation.utterances[utterance].paragraphs.length
          ).map(paragraph => makeAnnotationSpan(conversation, a, utterance, paragraph))
        }),

        ...range(speakerParagraphIndex, a.paragraphTo).map(paragraph =>
          makeAnnotationSpan(conversation, a, a.utteranceTo, paragraph)
        ),

        makeAnnotationSpan(conversation, a, a.utteranceTo, a.paragraphTo, 0, a.highlightedToCursor)
      ]
    } else if (a.paragraphFrom !== a.paragraphTo) {
      // Cross paragraph
      return [
        makeAnnotationSpan(conversation, a, a.utteranceFrom, a.paragraphFrom, a.highlightedFromCursor),

        ...range(a.paragraphFrom + 1, a.paragraphTo).map(paragraph =>
          makeAnnotationSpan(conversation, a, a.utteranceFrom, paragraph)
        ),

        makeAnnotationSpan(conversation, a, a.utteranceFrom, a.paragraphTo, 0, a.highlightedToCursor)
      ]
    } else {
      // Within a single paragraph
      return [
        makeAnnotationSpan(conversation, a, a.utteranceFrom, a.paragraphFrom, a.highlightedFromCursor, a.highlightedToCursor)
      ]
    }
  })
}

function makeSearchTermSpans (
  conversation: CommonConversation,
  terms: string[]
): Span<string>[] {
  terms = terms.filter(t => t.length > 0)
  if (!terms.length) {
    return []
  }

  const spans: Span<string>[] = []

  conversation.utterances?.forEach((utterance, utteranceIndex) => {
    utterance.paragraphs.forEach((paragraph, paragraphIndex) => {
      const regExp = buildKeywordsRegExp(terms)

      let match
      while ((match = regExp.exec(paragraph)) !== null) {
        spans.push({
          objects: [match[0]],
          utterance: utteranceIndex,
          paragraph: paragraphIndex,
          highlightedFromCursor: match.index,
          highlightedToCursor: match.index + match[0].length
        })
      }
    })
  })

  return spans
}

function getHighlightClasses (
  highlightables: TranscriptHighlightable[],
  user?: { id: string },
  activeKeywordSnippet?: ConversationDocumentSnippet,
  activeCompanyMentionsCompany?: Company,
  activeCompanyMentionsSnippet?: TranscriptCompanyMentionsSnippet
): string[] {
  function highlightClass (h: TranscriptHighlightable): string {
    if (isTranscriptAudioSentence(h)) {
      return 'is-type-audio-sentence'
    } else if (isSearchTermHighlight(h)) {
      return 'is-type-search_term'
    } else if (
      activeCompanyMentionsSnippet &&
      isSelectedCompanyMentionsSnippetHighlight(h, activeCompanyMentionsSnippet)
    ) {
      return 'is-type-company-mentions-snippet company-mentions-snippet-selected'
    } else if (
      isCompanyMentionsSnippetHighlight(h)
    ) {
      return 'is-type-company-mentions-snippet'
    } else if (
      activeCompanyMentionsCompany &&
      isActiveCompanyMentionsCompany(h, activeCompanyMentionsCompany)
    ) {
      return 'is-transcript-entity is-active-company-mentions-company'
    } else if (isTranscriptEntity(h)) {
      return 'is-transcript-entity'
    } else if (isAnnotationHighlight(h) && (!user || user.id === h.user.id)) {
      return `is-type-${h.type.toLowerCase()}`
    } else if (isAnnotationHighlight(h)) {
      return `is-type-team-${h.type.toLowerCase()}`
    } else if (
      activeKeywordSnippet &&
      isSelectedTranscriptKeywordSnippetHighlight(h, activeKeywordSnippet)
    ) {
      return 'is-type-keyword-snippet keyword-snippet-selected'
    } else if (isTranscriptKeywordSnippetHighlight(h)) {
      return 'is-type-keyword-snippet'
    } else if (isHighlightRegion(h)) {
      return 'is-type-highlight-region'
    } else {
      throw new Error(`Unexpected object: ${h}`)
    }
  }

  return Array.from(new Set(highlightables.map(highlightClass)))
}

// getHighlightSpanAnnotations orders annotations: it puts the most recent ones
// first.
function getHighlightSpanAnnotations (
  highlightables: TranscriptHighlightable[]
): Annotation[] {
  return highlightables
    .filter(isAnnotationHighlight)
    .sort((a, b) => Number(b.id) - Number(a.id))
}

function getKeywordSnippetSpans (
  highlightables: TranscriptHighlightable[]
): ConversationDocumentSnippet[] {
  return highlightables.filter(isTranscriptKeywordSnippetHighlight)
}

function getCompanyMentionsSnippetSpans (
  highlightables: TranscriptHighlightable[]
): TranscriptCompanyMentionsSnippet[] {
  return highlightables.filter(isCompanyMentionsSnippetHighlight)
}

function spansToTextFragments (
  text: string,
  spans: Span<TranscriptHighlightable>[],
  user?: { id: string },
  activeKeywordSnippet?: ConversationDocumentSnippet,
  activeCompanyMentionsCompany?: Company,
  activeCompanyMentionsSnippet?: TranscriptCompanyMentionsSnippet
): TextFragment<TranscriptHighlightable>[] {
  let offset = 0
  const fragments: TextFragment<TranscriptHighlightable>[] = []

  spans.forEach(s => {
    if (offset < s.highlightedFromCursor) {
      fragments.push({
        span: null,
        text: text.slice(offset, s.highlightedFromCursor)
      })
    }

    const companyMentionsSnippets = getCompanyMentionsSnippetSpans(s.objects)
    const keywordSnippets = getKeywordSnippetSpans(s.objects)
    const annotations = getHighlightSpanAnnotations(s.objects)

    const entity = s.objects.find(object => {
      return isTranscriptEntity(object) && (object as DocumentEntity)
    })
    fragments.push({
      span: {
        object: annotations[0],
        from: s.highlightedFromCursor,
        to: s.highlightedToCursor,
        classes: getHighlightClasses(s.objects, user, activeKeywordSnippet, activeCompanyMentionsCompany, activeCompanyMentionsSnippet),
        ids: annotations.map(a => a.id).concat(keywordSnippets.map(k => k.id)).concat(companyMentionsSnippets.map(m => m.id))
      },
      text: text.slice(s.highlightedFromCursor, s.highlightedToCursor),
      ...(entity && { entity: entity as DocumentEntity })
    })
    offset = s.highlightedToCursor
  })

  if (offset < text.length) {
    fragments.push({
      span: null,
      text: text.slice(offset)
    })
  }

  return fragments
}

function makeSpans (
  conversation: TranscriptConversation | EventTranscriptSection | ExpertInterviewConversation | InterviewConversation,
  annotations: Annotation[],
  keywords: string[],
  keywordSnippets: ConversationDocumentSnippet[],
  currentAudioSentence: UtteranceAudioSentence | ConversationSentenceAudioWithUtterance | undefined,
  entities: DocumentEntity[],
  companyMentionsSnippets: TranscriptCompanyMentionsSnippet[],
  highlightRegions: HighlightRegion[]
): Span<TranscriptHighlightable>[] {
  const spans: Span<TranscriptHighlightable>[] = [
    ...makeAnnotationSpans(conversation, annotations),
    ...makeKeywordSnippetSpans(conversation, keywordSnippets),
    ...makeSearchTermSpans(conversation, keywords),
    ...(currentAudioSentence ? makeSentenceAudioSpans(currentAudioSentence) : []),
    ...makeEntitySpans(entities),
    ...makeCompanyMentionsSnippetSpans(companyMentionsSnippets),
    ...makeHighlightRegions(conversation, highlightRegions)
  ]
  const groupedByParagraph = groupBy(spans, s => String([s.utterance, s.paragraph]))

  return flatMap(Object.entries(groupedByParagraph), ([key, spans]) => {
    const [utterance, paragraph] = key.split(',').map(Number)
    return splitOverlappingSpans(utterance, paragraph, spans)
  })
}

export function highlightConversation (
  conversation: TranscriptConversation | EventTranscriptSection | ExpertInterviewConversation | InterviewConversation,
  annotations: Annotation[],
  keywords: string[],
  user?: { id: string },
  keywordSnippets: ConversationDocumentSnippet[] = [],
  activeKeywordSnippetIndex: number | null = null,
  currentAudioSentence: UtteranceAudioSentence | ConversationSentenceAudioWithUtterance | undefined = undefined,
  entities: DocumentEntity[] = [],
  companyMentionsSnippets: TranscriptCompanyMentionsSnippet[] = [],
  activeCompanySnippetIndex: number | null = null,
  highlightRegions: HighlightRegion[] = []
): Conversation {
  const spans = makeSpans(
    conversation,
    annotations,
    keywords,
    keywordSnippets,
    currentAudioSentence,
    entities,
    companyMentionsSnippets,
    highlightRegions
  )
  const activeSnippet =
    activeKeywordSnippetIndex !== null && activeKeywordSnippetIndex >= 0
      ? keywordSnippets[activeKeywordSnippetIndex]
      : undefined

  const activeCompanyMentionsCompany = companyMentionsSnippets[0]?.company || undefined

  const activeCompanyMentionsSnippet =
    activeCompanySnippetIndex !== null && activeCompanySnippetIndex >= 0
      ? companyMentionsSnippets[activeCompanySnippetIndex]
      : undefined

  function highlightSpeaker (text: string, utterance: number) {
    const speakerSpans = spans.filter(
      s => s.utterance === utterance && s.paragraph === speakerParagraphIndex
    )
    return spansToTextFragments(text, speakerSpans, user, activeSnippet, activeCompanyMentionsCompany, activeCompanyMentionsSnippet)
  }

  function highlightSpeakerDetails (text: string | undefined | null, utterance: number) {
    if (!text) return undefined

    return highlightSpeaker(text, utterance)
  }

  function highlightParagraph (text: string, utterance: number, paragraph: number) {
    const paragraphSpans = spans.filter(s => s.utterance === utterance && s.paragraph === paragraph)
    return spansToTextFragments(text, paragraphSpans, user, activeSnippet, activeCompanyMentionsCompany, activeCompanyMentionsSnippet)
  }

  function highlightUtterance (
    utterance: CommonUtterance,
    utteranceIndex: number
  ): Utterance {
    return {
      audio: 'audio' in utterance ? utterance.audio : undefined,
      speaker: highlightSpeaker(utterance.speaker, utteranceIndex),
      speakerDetails: 'speakerDetails' in utterance ? highlightSpeakerDetails(utterance.speakerDetails, utteranceIndex) : undefined,
      paragraphs: utterance.paragraphs.map((p, paragraphIndex) => {
        return highlightParagraph(p, utteranceIndex, paragraphIndex)
      }),
      isClient: 'isClient' in utterance ? utterance.isClient : undefined
    }
  }

  return { utterances: conversation.utterances?.map(highlightUtterance) }
}
